import React from 'react';
import logo from './assets/Carriage_House_Logo_Flat.svg';
import codeableLogo from './assets/codeable-logo.svg';
import './App.css';

const App: React.FC = () => {
  const displayName = 'Carriage House Developers';
  const email = 'info@carriagehouse.dev';
  const callToActionText = 'Hire us on';
  const links = [
    {
      _id: '8c421b8b13014668b0f5d7de30e55a6c',
      type: 'codeable',
      displayName: 'Codeable',
      url:
        'https://codeable.io/developers/billay-brooks-carriage-house-developers/',
    },
  ];
  const currentYear = new Date().getFullYear();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt={displayName} />
        <span>{displayName}</span>
      </header>
      <div className="App-main">
        <span>{callToActionText}</span>
        <a
          key={links[0]._id}
          href={links[0].url}
          target="_blank"
          rel="noopener noreferrer"
          className={`${links[0].type}-logo`}
        >
          <img
            src={codeableLogo}
            alt={`${displayName} on ${links[0].displayName}`}
          />
        </a>
      </div>
      <footer className="App-footer">
        <div>
          <a href={`mailTo:${email}`}>{email}</a>
        </div>
        <div>
          &copy;
          {` ${currentYear} ${displayName}`}
        </div>
      </footer>
    </div>
  );
};

export default App;
